import { snakeCase } from 'lodash';

const GQL_REQUEST_QUERY_MODIFIER_REGEX = /(?:query|mutation)\s+(\w+)/gi;

export function sleep(sec) {
  return new Promise(resolve => {
    setTimeout(resolve, sec * 1000);
  });
}

export function getUsername(user) {
  return user?.username;
}

export function isWindwardUser(user) {
  return getUserProjectName(user) === 'windward';
}

export function getUserSelectedProjectName(user) {
  return user?.selectedOrganization?.name || user?.organization?.name;
}

export function getUserSelectedProjectId(user) {
  return user?.selectedOrganization?.id || user?.organization?._id;
}

export function getUserProjectName(user) {
  return user?.organization?.name;
}

export function getUserProjectId(user) {
  return user?.organization?._id;
}

export function isSelectedWindwardProject(user) {
  return getUserSelectedProjectName(user) === 'windward';
}

export function getUserSelectedProject(user) {
  return {
    name: getUserSelectedProjectName(user),
    id: getUserSelectedProjectId(user)
  };
}

export function getUserEmail(user) {
  return user?.email;
}

export function getTenantId(user) {
  return user?.tenantId;
}

export function isExecutedOnClientSide() {
  try {
    return !!globalThis.document;
  } catch (e) {
    return false;
  }
}

export function getServiceName() {
  return isExecutedOnClientSide()
    ? globalThis.location.hostname.replace(/\./g, '_')
    : process.env.SERVICE_NAME;
}

export function getModifiedQueryWithServiceNameSuffix(query) {
  const serviceName = getServiceName();

  if (!serviceName) {
    return query;
  }

  const suffix = snakeCase(serviceName);
  const modifiedQuery = query.replace(
    GQL_REQUEST_QUERY_MODIFIER_REGEX,
    (match, p1) => `${match.replace(p1, `${p1}_${suffix}`)}`
  );

  return modifiedQuery;
}
