import { gql } from '@apollo/client';

export const changePassword = gql`
  mutation changePassword($password: String!, $newPassword: String!) {
    changePassword(password: $password, newPassword: $newPassword) {
      success
      reason
    }
  }
`;

export const setFirstPassword = gql`
  mutation setFirstPassword($password: String!) {
    setFirstPassword(password: $password) {
      status
    }
  }
`;

export const selectProject = gql`
  mutation selectOrganization(
    $organizationId: ObjectId!
    $useRefreshToken: Boolean
    $useTemporaryCode: Boolean!
  ) {
    selectOrganization(organizationId: $organizationId, useRefreshToken: $useRefreshToken) {
      code @include(if: $useTemporaryCode)
      tokens @skip(if: $useTemporaryCode) {
        accessToken
        refreshToken
      }
      user @skip(if: $useTemporaryCode) {
        eulaApprovalDate
        featureFlags
        supportServiceMetadata
        organization {
          displayName
        }
      }
    }
  }
`;

export const login = gql`
  mutation appLogin(
    $username: String!
    $password: String!
    $forceLogin: Boolean
    $application: Application!
    $useRefreshToken: Boolean
    $useTemporaryCode: Boolean!
  ) {
    appLogin(
      username: $username
      password: $password
      forceLogin: $forceLogin
      application: $application
      useRefreshToken: $useRefreshToken
    ) {
      code @include(if: $useTemporaryCode)
      tokens {
        accessToken
        refreshToken @skip(if: $useTemporaryCode)
      }
      loginOutputResult
      userPreferences @skip(if: $useTemporaryCode) {
        language
      }
      user @skip(if: $useTemporaryCode) {
        eulaApprovalDate
        featureFlags
        supportServiceMetadata
        organization {
          displayName
        }
      }
    }
  }
`;

export const loginWithOAuthToken = gql`
  mutation appLoginOAuthToken($input: AppLoginOAuthTokenInput!, $useTemporaryCode: Boolean!) {
    appLoginOAuthToken(input: $input) {
      code @include(if: $useTemporaryCode)
      tokens @skip(if: $useTemporaryCode) {
        accessToken
        refreshToken
      }
      userPreferences @skip(if: $useTemporaryCode) {
        language
      }
      user @skip(if: $useTemporaryCode) {
        eulaApprovalDate
        featureFlags
        supportServiceMetadata
        organization {
          displayName
        }
      }
    }
  }
`;
