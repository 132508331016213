import axios from 'axios';
import { AxiosError, AxiosGraphqlError, GraphQLClientError } from 'exceptions';
import { set } from 'lodash';
import { getModifiedQueryWithServiceNameSuffix } from './general';

const CancelTokenProvider = axios.CancelToken;

export async function httpGet(url, config = {}) {
  try {
    return await axios.get(url, config);
  } catch (e) {
    throw new AxiosError(e);
  }
}

export function addRequestTimeInterceptors() {
  axios.interceptors.request.use(request => {
    set(request, 'meta.requestStartedAt', Date.now());

    return request;
  });

  axios.interceptors.response.use(response => {
    const res = response;
    res.responseTime = Date.now() - res.config.meta.requestStartedAt;

    return response;
  });
}

/**
 * @deprecated @ww/js-core/utils/http/get is deprecated. Use @ww/js-core/utils/http/httpGet instead
 */
export async function get(url, params, config) {
  console.warn(
    'DeprecationWarning: @ww/js-core/utils/http/get is deprecated. Use @ww/js-core/utils/http/httpGet instead'
  );

  return httpGet(url, {
    params,
    ...config
  });
}

export async function post(url, body, config = {}, cancelableRequestSource) {
  try {
    return await axios.post(url, body, {
      ...config,
      cancelToken: cancelableRequestSource && cancelableRequestSource.token
    });
  } catch (e) {
    throw new AxiosError(e);
  }
}

export async function httpDelete(url, config) {
  try {
    return await axios.delete(url, config);
  } catch (e) {
    throw new AxiosError(e);
  }
}

export function getCancelableRequestSource() {
  return CancelTokenProvider.source();
}

export function isRequestCanceled(thrown) {
  return axios.isCancel(thrown);
}

export function setDefaultHeader(header, value) {
  const { headers } = axios.defaults;

  headers.common[header] = value;
}

export async function gqlRequest(baseURL, { query, variables, headers }) {
  return internalGQLRequest(axios, { baseURL, query, variables, headers });
}

export function createGql(baseURL) {
  const instance = axios.create({ baseURL });

  return async ({ query, variables, headers }, cancelableRequestSource) => {
    return internalGQLRequest(instance, {
      query,
      variables,
      headers,
      cancelableRequestSource
    });
  };
}

async function internalGQLRequest(
  axiosInstance,
  { baseURL = '', query, variables, headers, cancelableRequestSource }
) {
  let result;

  const modifiedQuery = getModifiedQueryWithServiceNameSuffix(query);

  try {
    result = await axiosInstance.post(
      baseURL,
      { query: modifiedQuery, variables },
      {
        cancelToken: cancelableRequestSource,
        headers: {
          ...(headers ?? false)
        }
      }
    );
  } catch (e) {
    throw new AxiosGraphqlError(e);
  }

  if (result.data.errors?.length) {
    throw new GraphQLClientError(result.data.errors);
  }

  return result.data;
}
