import { useContext, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { LoginContext } from '@ww/react-ui-components';

import { isPrevUrlValid } from '/utils/url';
import { useAnalytics } from '/utils/analytics';
import PathContext from '/components/Login/PathContext';

const SET_PROJECT_PATH = 'set-project';
const SET_PASSWORD_PATH = 'set-password';

export const AUTH_TYPE_SERVER = 'server';

export function useAutoRedirect() {
  const history = useNavigate();
  const location = useLocation();

  const {
    state: {
      prevUrl,
      code,
      token,
      refreshToken,
      language,
      isOtp,
      shouldSelectProject,
      authType,
      userData,
    },
  } = useContext(LoginContext);
  const { prefix, authService } = useContext(PathContext);

  const analytics = useAnalytics();

  useEffect(() => {
    const validCredentials = authService ? Boolean(token) : Boolean(code);

    if (!isOtp && !shouldSelectProject && validCredentials && isPrevUrlValid(prevUrl)) {
      analytics?.track('login');

      // If authService provided it means the "temporary code mechanism" is not used,
      // we just navigate to the app (to the desired "prevUrl")
      if (authService) {
        authService.onLoginSuccess({
          tokens: { accessToken: token, refreshToken },
          language,
          prevUrl,
          userData,
        });

        return;
      }

      const urlObj = new URL(prevUrl ?? '');

      // At this point we use the "temporary code mechanism":
      //  Note: Since we don't want code param to get sent to Server we are adding # only if not available else we are not sending it
      const codeKeyPrefix = urlObj.hash ? '&' : '#';
      const urlWithCode = `${prevUrl}${
        authType === AUTH_TYPE_SERVER ? '?' : codeKeyPrefix
      }code=${code}`;
      window.location.replace(urlWithCode);
    } else if (code) {
      /* eslint-disable-next-line */
      console.error('unable to redirect. prevUrl is invalid');
    }
  }, [prevUrl, code, isOtp, shouldSelectProject, token, refreshToken, language, userData]);

  useEffect(() => {
    if (isOtp && token && !location.pathname.includes(SET_PASSWORD_PATH)) {
      analytics?.track('user_should_set_password');
      history(`${prefix ?? ''}/${SET_PASSWORD_PATH}`);
    }
  }, [isOtp, token]);

  useEffect(() => {
    if (shouldSelectProject && prevUrl && token && !location.pathname.includes(SET_PROJECT_PATH)) {
      analytics?.track('user_should_set_project');
      history(`${prefix ?? ''}/${SET_PROJECT_PATH}`);
    }
  }, [shouldSelectProject]);
}
