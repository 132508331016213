// @flow

export const Apps = Object.freeze({
  MARINT: 'marint',
  PLAYGROUND: 'playground',
  OFV: 'ofv',
  EMANUEL: 'emanuel',
  FUSES_ANALYZER: 'fusesAnalyzer'
});

export type AppName = $Values<typeof Apps>;
